// Colors
$green: #1ed760;
$darker-green: #1db954;
$user-msg-blue: #3f7ef4;
$login-first-blue: #0D72EA;
$black: #121212;
$black-bcg: #181818;
$confirm-delete-black: #1A1A1A;
$grey: #6A6A6A;
$grey-txt: #acaaaa;
$grey-txt-2: #a7a7a7;
$grey-hover: #313131;

// Breakpoints
$mobile-breakpoint: 650px;
$narrow-breakpoint: 800px;
$wide-breakpoint: 1200px;

$list-max-width: 1920px;