.confirm-delete-msg-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 30;

    .confirm-delete-msg {
        background-color: white;
        color: $confirm-delete-black;
        height: 200px;
        width: 400px;
        padding: 25px;
        border-radius: 25px;
        z-index: 30;

        .btns {
            margin-top: 35px;
            justify-content: space-around;
            grid-template-columns: 2;
            height: 40px;
            gap: rem(10px);

            &>* {
                &:hover {
                    transform: scale(1.1);
                    transition: 0.4s;
                }
            }

            &>:first-child {
                grid-column: 1;
                height: 40px;
                width: 80px;

                button {
                    border: none;
                    color: $grey;
                    background-color: white;
                    font-weight: 700;
                    font-size: rem(14px);
                }

            }

            &>:last-child {
                grid-column: 2;
                height: 40px;
                width: 135px;

                button {
                    height: 45px;
                    padding: 0px 15px;
                    border-radius: 35px;
                    border: none;
                    font-weight: 700;
                    font-size: rem(14px);
                    background-color: $darker-green;
                    color: $confirm-delete-black;
                }
            }
        }
    }

    @include for-tablet-layout {
        .confirm-delete-msg {
            .btns {
                &>:last-child {
                    button {
                        font-size: rem(10px);
                    }
                }
            }
        }
    }
}