.admin-control-set {
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    background-color: $user-msg-blue;
    height: 350px;
    width: 350px;
    border-radius: 25px;
    top: 5%;
    right: 50px;
    padding: 25px;


    &>* {
        border: none;
        background-color: white;
        width: 200px;
        height: 75px;
        border-radius: 50px;
        font-weight: bold;
        font-size: rem(20px);
        color: $black;
    }

    @include for-desktop-layout {
        display: flex;
    }
}