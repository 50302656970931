h1 {
    font-size: 22px;
    margin: 30px 25px 10px 20px
}

.fa-solid {
    font-size: 1.5rem;
    color: $grey-txt;
    padding: 8px;
}

@font-face {
    font-family: circular;
    src: url(../../assets/fonts/CircularFontFamily/AvenirLTStd-Heavy.otf);
}

@font-face {
    font-family: pacifico;
    src: url(../../assets/fonts/Pacifico/Pacifico-Regular.ttf);
}