.media-player-container {
    grid-area: mediaplayer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    height: 7.5%;
    width: 95%;
    left: 2.5%;
    bottom: 49px;
    margin: auto;
    background-color: $black-bcg;
    border-top: 1px solid #282828;
    padding: 0 10px;
    border-radius: 8px 8px 0 0;
    z-index: 20;

    .youtube-player {
        display: none;
    }

    .media-player-clip-preview {
        position: relative;
        top: 10%;
        left: -50%;
        transform: translate(50%, 0);
        gap: 10px;
        align-items: center;
        width: fit-content;
        height: 65%;

        &:not(:last-child) {
            // for mobile - expect play button
            justify-content: flex-start;
        }

        .media-player-clip-img {
            position: relative;
            width: 35px;
            height: 35px;
            clip-path: inset(12.5% 0% 12.5% 0% round 10px);
        }

        .media-player-clip-txt {
            &>* {
                margin: 0;
                padding: 0;
            }

            .mp-clip-title {
                font-size: 1rem;
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .mp-clip-artist {
                font-size: calc(1rem - 2px);
                color: $grey-txt;
            }
        }

        .like-icon {
            display: none;
        }
    }

    .mp-controller {
        position: relative;
        // top: -35px;
        width: 90vw;

        .mp-btn-container {
            display: flex;
            justify-content: flex-end;
            gap: rem(10px);
            position: relative;
            bottom: -11px;
            left: -5px;

            .action-btn {
                font-size: rem(20px);
                color: $grey-txt;
                background: none;
                border: none;
                padding: 0px;
                cursor: pointer;

                &:hover {
                    color: white;
                }
            }

            &>:not(.play-btn) {
                display: none;
            }

            .play-btn {
                position: relative;
                font-size: rem(36px);
                height: rem(40px);
                width: rem(40px);
            }

            .skip10sec-btn {
                transform: translateY(5%);

                svg {
                    font-size: rem(27.5px);
                }
            }

        }

        .time-line-container {
            display: flex;
            justify-content: center;
            position: relative;
            top: 5%;

            .time-line-input {
                width: 90%;
                outline: 0;
            }

            .track-time {
                display: none;
            }
        }
    }

    .mp-2nd-controller {
        display: none;
        flex-direction: column;
        align-items: center;

        .toggle-mute-btn {
            border: none;
            background: none;
            color: #b3b3b3;
            margin: 0 8px;
            font-size: 1rem;
            color: #b3b3b3;
        }

        .queue-icon {
            display: block;
            cursor: pointer;
            color: $grey-txt;
        }

        .volume-input-container {
            width: 60px;
            margin-inline-end: 5px;

            .volume-input {
                display: flex;
                justify-content: center;
            }
        }
    }

    @include for-tablet-layout {
        flex-direction: row;
        bottom: 0;
        width: 100%;
        height: rem(80px);
        left: 0;

        .media-player-clip-preview {
            display: flex;
            top: 0;
            left: 0;
            transform: none;
            .media-player-clip-img {
                margin-left: 5px;
                width: 60px;
                height: 60px;
            }

            .media-player-clip-txt {
                display: none;
            }

            .like-icon {
                display: block
            }
        }


        .mp-controller {
            position: unset;
            width: 50%;
            padding-top: rem(10px);
            gap: 5px;

            .mp-btn-container {
                justify-content: space-between;
                margin: 0 auto;

                &>:not(.play-btn) {
                    display: block;
                }

                .play-btn {
                    font-size: rem(16px);
                    background-color: #c5c5c5;
                    color: black;

                    &:hover {
                        background-color: white;
                        color: black;
                        transition: 0.3s;
                    }
                }
            }

            .time-line-container {
                .time-line-input {
                    width: 100%;
                    transform: translateY(0);
                }

                .track-time {
                    display: unset;
                    font-size: 12px;
                    padding: 8px;
                    color: $grey-txt;
                }
            }
        }

        .mp-2nd-controller {
            display: flex;
        }
    }

    @include for-desktop-layout {
        .media-player-clip-preview {
            .media-player-clip-txt {
                white-space: normal;
                letter-spacing: -.01rem;
                display: block;
            }
        }

        .mp-2nd-controller {
            flex-direction: row;

            .volume-input-container {
                width: 95px;
            }
        }
    }
}