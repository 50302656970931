.station-header {
    .station-header-title {

        .station-img-container {
            padding: rem(30px);

            .station-img {
                width: rem(350px);
                height: rem(350px);
            }
        }

        .sh-title {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: rem(30px);

            h5 {
                margin: 0;
                font-size: rem(20px);
            }

            .station-name {
                font-size: rem(70px);
                margin: 0;
            }

            p {
                font-size: rem(18px);
                margin: 0;
            }

            .station-header-details {
                margin-top: 10px;
                color: $grey-txt;

                .user-name {
                    text-transform: capitalize;
                    font-weight: bold;
                    font-size: rem(24px);
                }

                font-size: rem(18px);

            }
        }
    }

    .playlist-btns {
        display: flex;
        gap: 35px;
        height: fit-content;
        align-items: center;
        padding: rem(20px) rem(45px);

        & svg {
            height: 32px;
        }

        .play-btn-container {
            .play-btn {
                width: rem(95px);
                height: rem(95px);
                font-size: rem(35px);

                &.fa-pause {
                    display: none;
                }

                &:hover {
                    transform: scale(1.1);
                    transition: 0.4s;
                }

            }

            .equalizer-img {
                width: rem(55px);
                height: rem(55px);
            }

            &:hover {
                transition: 0.4s;

                & .equalizer {
                    display: none;
                    transition: 0.4s;
                }

                & .play-btn.fa-pause {
                    display: unset;
                    transform: scale(1);
                    transition: 0.4s;
                }
            }
        }

        .admin-state-btn {
            font-size: 32px;
            cursor: pointer;
            display: none;
        }

    }

    .dropdown {
        top: 25%;

    }

    @include for-tablet-layout {
        .station-header-title {
            .station-img-container {
                padding: rem(10px);

                .station-img {
                    width: rem(150px);
                    height: rem(150px);
                }
            }

            .sh-title {
                padding: rem(10px);

                h5 {
                    font-size: rem(16px);
                }

                .station-name {
                    font-size: rem(45px);
                }

                p {
                    font-size: rem(8px);
                }

                .station-header-details {
                    .user-name {
                        font-size: rem(12px);
                    }

                    font-size: rem(8px);
                }
            }
        }

        .playlist-btns {
            .play-btn-container {
                .play-btn {
                    width: rem(65px);
                    height: rem(65px);
                    font-size: rem(25px);
                }
            }
        }


    }

    @include for-desktop-layout {
        .station-header-title {
            .station-img-container {
                padding: rem(20px);

                .station-img {
                    width: rem(250px);
                    height: rem(250px);
                }
            }

            .sh-title {
                padding: rem(20px);

                h5 {
                    font-size: rem(16px);
                }

                .station-name {
                    font-size: rem(55px);
                }

                p {
                    font-size: rem(12px);
                }

                .station-header-details {
                    .user-name {
                        font-size: rem(16px);
                    }

                    font-size: rem(12px);
                }
            }
        }

        .playlist-btns {
            .admin-state-btn {
                display: unset;
            }
        }

        .dropdown {
            top: 45%;
        }

    }


    @include for-large-desktop-layout {
        .station-header-title {
            .sh-title {
                .station-name {
                    font-size: rem(65px);
                    line-height: 1;
                }
            }
        }

        .dropdown {
            top: 32%;
        }
    }
}