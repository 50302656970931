.login-first-msg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -250px;
    width: 300px;
    height: fit-content;
    background-color: $login-first-blue;
    padding: 1rem;
    border-radius: 20px;
    z-index: 20;

    .tippy {
        position: absolute;
        height: 0;
        width: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        left: rem(-10px);
        top: rem(20px);
        border-right: 10px solid $login-first-blue;
        display: none;
    }

    .login-first-txt-container {
        & .login-first-msg-title {
            font-size: 18px;
            font-weight: 700;
            margin: 0px 0px 8px 0px;
        }

        & .login-first-msg-txt {
            font-size: 14px;
            margin: 0;
        }
    }

    .login-first-btns {
        margin-top: 35px;
        justify-content: flex-end;
        grid-template-columns: 2;
        font-size: 14px;
        height: 40px;
        z-index: 20;

        &>* {
            &:hover {
                transform: scale(1.1);
                transition: 0.4s;
            }
        }

        &>:first-child {
            grid-column: 1;
            height: 40px;
            width: 80px;

            button {
                border: none;
                background-color: $login-first-blue;
                font-weight: 700;
                color: white;
            }
        }

        &>:last-child {
            grid-column: 2;
            height: 40px;
            width: 100px;

            button {
                height: 35px;
                width: 75px;
                border-radius: 35px;
                border: none;
                font-weight: 700;
                color: $grey;
            }
        }
    }

    @include for-tablet-layout {
        left: 350px;

        .tippy {
            display: unset;
        }
    }

    @include for-desktop-layout {
        left: 405px;

        .tippy {
            left: rem(-8.4px);
        }
    }
}