.queue {
    h1 {
        margin-left: 0;
        padding-inline-start: 15px;
    }

    .queue-main-container {
        padding-inline-start: 30px;
    }

    @include for-large-desktop-layout{
        max-width: $list-max-width;
    }
}