.main-layout {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr 75px 60px;
    grid-template-areas: 'header' 'main' 'mediaplayer' 'sidebar';

    .main-content {
        grid-area: main;
        padding-bottom: 50%;
    }

    @include for-tablet-layout {
        grid-template-columns: 200px 1fr;
        grid-template-rows: 65px 1fr 90px;
        grid-template-areas:
            'sidebar header'
            'sidebar main'
            'mediaplayer mediaplayer'
        ;
    }

    @include for-desktop-layout {
        grid-template-columns: 252px 1fr;
        grid-template-rows: 65px 1fr 90px;
        grid-template-areas:
            'sidebar header'
            'sidebar main'
            'mediaplayer mediaplayer'
        ;
    }
}