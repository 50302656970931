.station-preview {
    height: 225px;
    border-radius: 15px;
    padding: 15px;
    background-color: $black-bcg;

    .station-preview-container {

        a {
            display: flex;
            justify-content: center;
        }

        .station-preview-img-container {
            height: clamp(135px, 20vw, 200px);
            width: clamp(135px, 20vw, 200px);
            margin: auto;

            .station-preview-img {
                height: 100%;
                width: 100%;
                margin-bottom: 16px;
                box-shadow: 0px 15px 24px -2px $grey-hover;
                border-radius: 5px;
            }

            .play-btn {
                position: relative;
                left: calc(clamp(135px, 20vw, 200px) - 60px);
                bottom: 85px;
                display: none;
            }
        }


        .desc-container {
            h4 {
                font-size: 3vw;
            }

            p {
                font-size: 1.5vw;
            }
        }
    }

    @include for-tablet-layout {
        height: 245px;

        .station-preview-container {
            .desc-container {
                h4 {
                    font-size: rem(20px);
                }

                p {
                    font-size: rem(10px);
                }
            }
        }
    }

    @include for-desktop-layout {
        height: 285px;

        .station-preview-container {
            .station-preview-img-container {
                height: clamp(135px, 16.5vw, 220px);
                width: clamp(135px, 16.5vw, 220px);

                .play-btn {
                left: calc(clamp(135px, 16.5vw, 220px) - 60px);
                }
            }

            .desc-container {
                h4 {
                    font-size: rem(17.5px);
                }
            }
        }

    }

    @include for-large-desktop-layout {
        height: 350px;

        .station-preview-container {
            .desc-container {
                h4 {
                    font-size: rem(20px);
                }

                p {
                    font-size: rem(14px);
                }
            }
        }
    }


    &:hover {
        background-color: $grey-hover;
        transition: all 0.5s;

        .station-preview-container {
            background-color: $grey-hover;
            transition: all 0.5s;

            &:hover {
                .play-btn {
                    display: block;
                }
            }
        }
    }
}