.profile-header {
    width: 100%;

    .profile-header-main-container {
        display: flex;
        align-items: center;
        padding: 10px 25px 0;
        gap: rem(15px);
        margin-bottom: 50px;

        .profile-header-img-container {
            .profile-img {
                height: 25vw;
                width: 25vw;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        .profile-header-details {
            justify-content: center;
            font-size: 2.225vw;

            .profile-title {
                margin-bottom: 0;
            }

            .profile-name {
                font-size: 10vw;
                // padding-inline-start: 10px;
                margin: 10px;
                line-height: 1;
            }

            .personal-details-container {
                margin-top: 5px;
            }
        }
    }

    .profile-btn-container {
        margin-inline-start: rem(20px);

        .profile-btn-main-container {
            align-items: center;
            gap: 20px;

            .play-btn {
                width: rem(65px);
                height: rem(65px);
                font-size: rem(25px);

                &:hover {
                    transform: scale(1.1);
                }

            }

            .toggle-follow-btn {
                background-color: #00000000;
                color: white;
                border: 1px solid white;
                font-size: rem(18px);
                font-weight: bold;
                padding: 10px 25px;
            }
        }
    }

    @include for-tablet-layout {
        .profile-header-main-container {
            gap: rem(20px);

            .profile-header-img-container {
                .profile-img {
                    height: rem(120px);
                    width: rem(120px);
                }
            }

            .profile-header-details {

                .profile-title {
                    font-size: rem(10px);
                }

                .profile-name {
                    font-size: rem(40px);
                }

                .personal-details-container {
                    font-size: rem(8px);
                }
            }
        }
    }

    @include for-desktop-layout {
        .profile-header-main-container {
            gap: rem(10px);

            .profile-header-img-container {
                .profile-img {
                    height: rem(175px);
                    width: rem(175px);
                }
            }

            .profile-header-details {
                font-size: rem(10px);

                .profile-title {
                    font-size: rem(20px);
                }

                .profile-name {
                    font-size: rem(65px);
                }

                .personal-details-container {
                    font-size: rem(15px);
                }
            }
        }
    }

    @include for-large-desktop-layout {
        .profile-header-main-container {

            .profile-header-img-container {
                .profile-img {
                    height: rem(295px);
                    width: rem(295px);
                }
            }

            .profile-header-details {
                .profile-name {
                    font-size: rem(100px);
                }
            }
        }
    }
}