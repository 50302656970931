.clip-preview {
    display: block;
    align-items: center;

    .clip-preview-container {
        display: grid;
        grid-template-columns: 3fr 20fr 5fr 5fr 7.5fr;
        align-items: center;
        font-size: 2vw;
        height: fit-content;

        .play-btn-container {
            grid-column: 1/2;
            padding: rem(5px) rem(10px);
            font-size: rem(20px);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            .clip-num {
                font-size: rem(15px);
                display: none;
            }

            .equalizer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: rem(12px);
                width: rem(12px);
                max-width: 28.3px;
                display: none;

                img {
                    width: 90%;
                    height: 90%;
                }
            }

        }

        .clip-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .clip-img-container {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: rem(70px);
                    height: rem(70px);
                    clip-path: inset(12.5% 0% 12.5% 0% round 10px);
                }
            }


            .title-text {
                padding-inline-start: rem(15px);

                h1 {
                    font-size: 2.5vw;
                    width: fit-content;
                    margin: 0;
                    padding: 0;
                }

                p {
                    margin: 0;
                    padding: 0;
                    color: #6f6969;
                    text-align: left;
                }
            }
        }

        .cp-artist-name,
        .cp-date-added {
            display: none;
        }

        .like-icon {
            display: flex;
            justify-content: center;
        }

        .dropdown-btn {
            visibility: visible;
            cursor: pointer;
        }

        .dropdown {
            top: 20%;
            font-size: rem(20px);

            .dropdown-station-list {
                position: absolute;
                background-color: #1f1f1f;
                padding: 5px;
                border-radius: 5px;
                top: 90px;
                left: -195px;
            }
        }


    }

    @include for-tablet-layout {
        .clip-preview-container {
            .clip-title {

                .clip-img-container {
                    img {
                        width: rem(50px);
                        height: rem(50px);
                    }
                }

                .title-text {
                    h1 {
                        font-size: rem(10px);
                    }

                    p {

                        font-size: rem(7.5px);
                    }
                }
            }

            .play-btn-container {
                font-size: rem(15px);

                .clip-play-btn {
                    display: none;
                }

                .clip-num {
                    display: unset;
                }

                .equalizer {
                    display: flex;
                }
            }

            .dropdown {
                top: 27.5%;
            }

        }
    }

    @include for-desktop-layout {
        .clip-preview-container {
            grid-template-columns: 1fr 20fr 5fr 5fr 5fr 5fr 5fr;
            align-items: center;
            font-size: 1vw;
            padding: 0 10px;

            .clip-title {

                .clip-img-container {
                    img {
                        width: rem(40px);
                        height: rem(40px);
                    }
                }

                .title-text {
                    h1 {
                        font-size: rem(12px);
                    }

                    p {

                        font-size: rem(8px);
                    }
                }
            }

            .cp-artist-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: rem(14px);
                padding-inline-start: 5px;
                grid-column: 3/4;
                display: grid;
            }

            .cp-date-added {
                font-size: rem(8px);
                grid-column: 4/5;
                display: grid;
            }

            .like-icon {
                svg {
                    width: rem(45px);
                    height: rem(45px);
                }
            }


            .cp-duration {
                grid-column: 6;
                text-align: right;
                font-size: rem(16px);
            }

            .dropdown-btn {
                visibility: hidden;
            }

            .dropdown {
                top: 32.5%;
            }
        }
    }


    @include for-large-desktop-layout {
        .clip-preview-container {
            .clip-title {

                .clip-img-container {
                    img {
                        width: rem(55px);
                        height: rem(55px);
                    }
                }

                .title-text {
                    h1 {
                        font-size: rem(15px);
                    }

                    p {

                        font-size: rem(10px);
                    }
                }
            }

            .like-icon {
                svg {
                    width: rem(55px);
                    height: rem(55px);
                }
            }

            .dropdown {
                top: 50%;
                font-size: rem(16px);
            }
        }
    }

    &:hover {
        border-bottom: 1px solid transparent;
        background-color: $grey-hover;
        border-radius: 5px;

        .clip-preview-container {
            .clip-play-btn {
                display: unset;
            }

            .clip-num {
                display: none;
            }

            .equalizer {
                display: none;
            }

            .dropdown-btn {
                visibility: visible;
            }

        }
    }

}