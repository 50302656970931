.edit-modal {
    .edit-modal-container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80vw;
        
        height: rem(500px);
        background-color: #242424;
        padding: 20px;
        border-radius: rem(8px);
        position: fixed;
        z-index: 10;
        transition: all 0.6s;
        align-items: center;
        z-index: 20;

        .edit-modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .btn-close {
                cursor: pointer;
                font-size: rem(35px);
                background: none;
                border: none;
                color: #6b6b6b;
                font-weight: 700;
            }
        }

        .edit-modal-form-container {
            display: flex;
            gap: 10%;
            width: 100%;

            .img-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .edit-form-img {
                    width: 100%;
                    height: 100%;
                    max-height: rem(300px);
                    object-fit: contain;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                gap: 25px;
                width: 40%;

                .edit-form-input-name {
                    height: rem(46px);
                    border: none;
                    padding-inline-start: 7.5px;
                    font-size: rem(14px);
                    border-radius: 5px;
                    background-color: #3e3e3e;
                    color: white;
                }

                .edit-form-input-desc {
                    height: rem(88px);
                    border: none;
                    padding-left: 5px;
                    font-size: .875rem;
                    border-radius: 5px;
                    background-color: #3e3e3e;
                    color: white;
                }

                .btn-edit-save {
                    background-color: #fff;
                    width: rem(100px);
                    height: rem(50px);
                    font-size: rem(18px);
                    align-self: end;
                    margin-bottom: 20px;
                    border-radius: rem(12px);
                    border: none;
                    font-family: circular;
                }
            }
        }
    }

    @include for-tablet-layout {
        .edit-modal-container {
            top: 40%;
            max-height: rem(450px);
        }
    }

    @include for-desktop-layout{
        max-width: rem(700px);
    }

    @include for-large-desktop-layout {

        .edit-modal-container {
            top: 50%;
            max-width: rem(800px);
        }
    }
}