.about {
    .app-description {
        h1 {
            text-align: center;
        }

        .description-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 0 10%;
        }
    }

    .features-list {
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        grid-template-rows: 2fr 6fr;
        height: min-content;
        width: 80%;
        margin: 10px auto;
        background-color: #252525;
        border-radius: 25px;

        h1 {
            grid-column: 1/-1;
            grid-row: 1/2;
            text-align: center;
        }

        .desc-txt-container {
            grid-column: 2/3;
            grid-row: 2/3;

            h1 {
                margin: 0;
            }

            p {
                height: fit-content;
            }
        }

        .back-btn {
            grid-column: 1/2;
        }

        .next-btn {
            grid-column: 3/4;
        }


        &>button {
            grid-row: 2/3;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            background-color: transparent;
            border: none;
            color: white;

            svg {
                font-size: rem(75px);
            }
        }
    }


    .developers {
        h1 {
            display: flex;
            justify-content: center;
        }

        .developers-container {
            // margin: 10px 10px;
            display: flex;
            justify-content: center;
            gap: 5px;
        }
    }

    @include for-tablet-layout {
        .features-list {
            &>button {
                svg {
                    font-size: rem(60px);
                }
            }
        }
    }

    @include for-desktop-layout {
        .developers {
            .developers-container {
                gap: 15px;
            }
        }


    }
}