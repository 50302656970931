.profile-details{
    height: 100%;
    background: linear-gradient(transparent 0, $black 600px);

    .profile-details-main-container {
        gap: 35px;
    }

    .recently-played-container {
        padding-inline-start: rem(32px);
        padding-top: rem(32px);

        h1 {
            margin: 0;
            padding-inline-start: 15px;
        }

    }
}