.nav-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    margin: 0;
    padding-top: 7.5px;
    z-index: 10;
    background-color: black;

    .symbol-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        cursor: pointer;

        .symbol {
            height: rem(45px);
            text-align: center;
            color: $grey-txt;

            svg {
                font-size: rem(35px);
            }

            .symbol-txt {
                font-size: 5px;
            }

            &:hover {
                color: white;
                visibility: visible;
            }
        }

        .liked-songs-playing-icon {
            .equalizer-img {
                width: rem(25px);
                height: rem(25px);
                margin-top: 6px;
            }
        }


    }

    .home-link,
    .library-link,
    .search-link {
        .symbol-container {
            &>:nth-child(2) {
                display: none;
            }
        }

        &.active {
            &>.symbol-container {
                &>:nth-child(1) {
                    display: none;
                }

                &>:nth-child(2) {
                    display: unset;
                }
            }
        }
    }

    .liked-songs-link {
        display: none;

        .nav-likes-songs-logo {
            width: rem(30px);
        }
    }


    @include for-tablet-layout {
        display: flex;
        flex-direction: column;
        position: unset;
        height: fit-content;
        margin: 10px 0;
        padding: 0;
        padding: 15px 0 15px;

        .symbol-container {
            position: initial;
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            font-size: 15px;

            .symbol {
                grid-column: 1 / 2;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                color: $grey-txt;

                &:hover {
                    visibility: visible;
                }
            }

            .symbol-txt {
                grid-column: 2 / 4;
                grid-row: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .liked-songs-playing-icon {
                grid-row: 1;
                grid-column: 3/4;
            }
        }

        .liked-songs-link {
            .symbol-container.symbol-txt {
                grid-column: 2/3;
            }

            display: unset;
        }


        .library-link .symbol-container .login-first-msg {
            top: 200px;
        }

        .create-playlist-link .symbol-container .login-first-msg {
            top: 243px;
        }

        .liked-songs-link .symbol-container .login-first-msg {
            top: 286px;
        }
    }

    @include for-desktop-layout {
        .library-link .symbol-container .login-first-msg {
            top: 230px;
        }

        .create-playlist-link .symbol-container .login-first-msg {
            top:273px;
        }

        .liked-songs-link .symbol-container .login-first-msg {
            top: 316px;
        }
    }

    @include for-large-desktop-layout {
        .library-link .symbol-container .login-first-msg {
            top: 250px;
        }

        .create-playlist-link .symbol-container .login-first-msg {
            top: 305px;
        }

        .liked-songs-link .symbol-container .login-first-msg {
            top: 360px;
        }
    }
}