.library {
    margin: 0 10px;
    .station-list-header {
        display: none;
    }

    .create-1st-playlist {
        height: 60vh;
        font-size: rem(24px);
        gap: rem(16px);

        .library-icon {
            height: 100px;
            width: 100px;
        }

        h1 {
            margin: 0;
            font-size: rem(32px);
        }

        span {
            font-size: 0.85em;
        }

        button {
            text-align: center;
            font-weight: 700;
            font-size: 0.65em;
            padding: 12px 32px;
            border: none;
            border-radius: 50px;
        }
    }
}