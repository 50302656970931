.search-loader {
    margin: 150px;
    display: flex;
    justify-content: center;
    height: 200px;
}

.page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.large-loader {
    width: 90vw;
    height: 90vw;
    padding: 10px;

    @include for-tablet-layout {
        width: 60vw;
        height: 60vw;
    }

    @include for-desktop-layout {
        width: 50vw;
        height: 50vw;
    }

    @include for-large-desktop-layout {
        width: 35vw;
        height: 35vw;
    }
}

.medium-loader {
    width: rem(405px);
    height: rem(414px);
    padding: 30px;
}

.loader-img {
    padding: 30px;
    animation: rotation 4s infinite linear;

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }

}