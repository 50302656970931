.profile-preview {
    height: 240px;
    border-radius: 15px;
    padding: 10px;
    background-color: $black-bcg;

    .profile-preview-container {

        a {
            display: flex;
            justify-content: center;
        }


        .profile-img-container {
            height: clamp(150px, 20vw, 215px);
            width: clamp(150px, 20vw, 215px);
            margin: auto;

            img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
                margin-bottom: 16px;
                @include aspect-ratio(1, 1);
            }

            .play-btn {
                position: relative;
                left: 65%;
                bottom: 75px;
                display: none;
            }
        }

        .desc-container {
            h4 {
                font-size: 3vw;
            }

            p {
                font-size: 2vw;
            }
        }
    }

    @include for-tablet-layout {
        height: 265px;

        .profile-preview-container {
            .desc-container {
                h4 {
                    font-size: rem(20px);
                }

                p {
                    font-size: rem(12px);
                }
            }
        }
    }

    @include for-desktop-layout {
        height: 290px;

        .profile-preview-container {
            .profile-img-container {
                height: clamp(150px, 16.5vw, 220px);
                width: clamp(150px, 16.5vw, 220px);
            }

            .desc-container {
                h4 {
                    font-size: rem(17.5px);
                }

                p {
                    font-size: rem(14px);
                }
            }
        }
    }

    @include for-large-desktop-layout {
        height: 375px;

        .profile-preview-container {
            .desc-container {
                h4 {
                    font-size: rem(20px);
                }

                p {
                    font-size: rem(16px);
                }
            }
        }
    }

    &:hover {
        background-color: $grey-hover;
        transition: all 0.5s;

        &:hover {
            .play-btn {
                display: block;
            }
        }
    }
}