.liked-songs-preview {
    height: 225px;
    width: minmax(320px, 450px);
    grid-column: 1 / 3;
    background: #4300ff;
    background: linear-gradient(149.46deg, #450af5, #8e8ee5 99.16%);
    border-radius: 15px;
    padding: 10px;

    .liked-songs-preview-container {
        height: 100%;
        padding-inline-start: 15px;
        gap: 15px;

        p {
            margin: 0;
            font-size: rem(28px);
            font-weight: unset;
        }

        .title-preview {
            font-size: rem(32px);
            margin: 0;
            font-weight: 700;
            padding: 0 0 4px;
        }

        .play-btn-container {
            align-self: flex-end;
            margin-inline-end: 15px;
            display: none;
        }

        &:hover {
            .play-btn-container {
                display: flex;
            }
        }
    }

    @include for-tablet-layout {
        height: 245px;

        .liked-songs-preview-container {
            height: 100%;
            padding-inline-start: 15px;
            gap: 15px;

            p {
                font-size: rem(12px);
            }

            .title-preview {
                font-size: rem(25px);
            }
        }
    }

    @include for-desktop-layout {
        height: 285px;
    }

    @include for-large-desktop-layout {
        height: 350px;
        width: minmax(320px, 500px);

        .liked-songs-preview-container {

            p {
                font-size: rem(20px);
            }

            .title-preview {
                font-size: rem(35px);
            }
        }
    }
}