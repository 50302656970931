.login-signup-form-container {
    align-items: center;
    &>* {
        width: 55%;
    }

    h1 {
        text-align: center;
        margin-bottom: rem(50px);
    }

    @include for-desktop-layout {
        &>* {
            max-width: 450px;
        }
    }
}