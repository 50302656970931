.search {
    padding: 0 15px;

    @include for-mobile-layout {
        padding: 0;
    }

    .search-filter-btns {
        display: flex;
        flex-wrap: wrap;
        margin: rem(40px) 0 0 rem(20px);
        gap: rem(10px);

        .search-filter-btn {
            border-radius: 25px;
            background-color: rgb(26, 26, 26);
            color: white;
            border: none;
            padding: 8.5px 25px;
            font-size: rem(20px);
            font-family: circular;

            &.active {
                background-color: #676363;
            }

            @include for-mobile-layout {
                font-size: 12px;
            }
        }
    }

    .recently-search-container {
        display: none;

        .station-preview {
            .station-preview-container {
                .recent-search-delete-btn-container {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    .fa-xmark {
                        font-size: rem(30px);

                        &:hover {
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }

                .station-preview-img-container {
                    .station-preview-img {
                        clip-path: inset(12.5% 0% 12.5% 0% round 10px);
                        margin: 0;
                    }
                }

                .desc-container {
                    h4 {
                        margin-top: 0;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }



    @include for-tablet-layout {
        .search-filter-btns {
            gap: rem(2.5px);
            .search-filter-btn {
                font-size: rem(14px);
            }

            .recently-search-container {
                display: unset;
            }
        }
    }
}