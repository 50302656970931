
.sidebar {
    grid-area: sidebar;
    position: absolute;
    z-index: 10;

    .logo-container {
        padding-inline-start: rem(8px);
        display: flex;
        align-items: center;
        gap: 2.5px;

        .logo-name {
            position: relative;
            left: -50%;
            transform: translateX(50%);
            color: $green;
            font-size: rem(30px);
            font-weight: lighter;
            font-family: pacifico, sans-serif;
        }

        .logo-img {
            width: rem(100px);
            -webkit-transform: rotate(345deg);
            -moz-transform: rotate(345deg);
            -ms-transform: rotate(345deg);
            -o-transform: rotate(345deg);
            transform: rotate(345deg);
        }
    }

    hr {
        margin: 0 rem(22px);
    }

    .user-stations-container {
        padding: 0;
        margin: 10px 3px;
        background-color: black;

        li {
            padding: rem(2.5px) rem(20px);
            font-size: rem(16px);
        }
    }

    &>*:not(.nav-list) {
        display: none;
    }

    @include for-tablet-layout {
        position: sticky;
        top: 0;
        padding: 10px 0;
        background-color: black;
        height: 150%;
        
        &>*:not(.nav-list) {
            display: block;
        }

    }

    @include for-desktop-layout {
        .logo-container {
            .logo-name {
                left: -44%;
            }
        }

        .user-stations-container {
            li {
                font-size: rem(18px);
            }
        }
    }

    @include for-large-desktop-layout {
        .logo-container {
            .logo-name {
                left: -50%;
            }
        }
    }
}