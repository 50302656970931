.dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    width: max-content;
    height: fit-content;
    background-color: #222222;
    border-radius: 5px;
    transition: all 0.5s;
    list-style: none;
    padding: 10px;
    z-index: 20;

    &>*:not(.main-screen) {
        z-index: 20;
    }

    .share {
        z-index: 15;
    }

    li {
        display: flex;
        align-items: center;
        height: 35px;
        list-style: none;
        cursor: pointer;
    }

    li:hover {
        background-color: #757575;
    }

    @include for-mobile-layout {
        top: 200px;
        left: 40px;
        font-size: 12px;
    }
}