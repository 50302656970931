* {
    box-sizing: border-box;
}

html {
    font-family: circular;
    line-height: 1.6;
    font-size: 8px;
    max-width: 100%;
    overflow-x: hidden;

    @include for-tablet-layout {
        font-size: 14px;
    }

    @include for-desktop-layout {
        font-size: 16px;
    }

    @include for-large-desktop-layout {
        font-size: 18px;
    }
}

body {
    margin: 0;
    padding: 0;
    background-color: $black;
    color: white;
    position: relative;
    max-width: 100%;
}

a {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

img {
    object-fit: cover;
}

button {
    cursor: pointer;

    &:hover {
        transition: 0.3s;
    }
}

li {
    list-style: none;
}

.img-input {
    display: none;
}

ul {
    padding: 0;
}

hr {
    margin: 0;
    border: 1px solid #3a3939;
}

.list-header {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    padding: 25px 0 25px 0;

    h1 {
        margin: 0;
        text-transform: capitalize;
    }

    a {
        text-transform: uppercase;
        color: $grey-txt-2;

        &:hover {
            text-decoration: underline;
        }
    }
}

.play-btn {
    border-radius: 50%;
    background: $green;
    border: none;
    text-align: center;
    transition: 0.4s;
    width: 55px;
    height: 55px;
    font-size: clamp(20px, 50%, 30px);

    &:hover {
        transform: scale(1.1);
        transition: 0.4s;
    }

    &.fa-pause {
        display: block;
    }

    @include for-mobile-layout {
        display: none;
    }
}

.desc-container {
    &>* {
        margin: 0;
        padding: 0;
    }

    h4 {
        margin-top: 15px;
        font-size: 16px;
        padding-bottom: 4px;
    }

    p {
        color: $grey-txt-2;
        text-overflow: ellipsis;
    }
}

.dropdown-btn-container {
    height: 25px;
    align-items: center;
    padding-inline-start: 10px;
    gap: 3.5px;
    cursor: pointer;

    svg {
        font-size: 7.5px;
    }
}

.main-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity .6s;
    z-index: 10;
    cursor: pointer;

    &.confirm-delete-profile {
        opacity: 0.5;
        transition: opacity .6s;
        background-color: $grey;
    }
}

.rotate {
    animation: rotation 10s infinite linear;

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }
}

body::-webkit-scrollbar {
    width: 0.6em;

    @include for-mobile-layout {
        width: 0.4em;
    }
}

body::-webkit-scrollbar-thumb {
    background-color: #666;
}

::-webkit-scrollbar-thumb:hover {
    background: #888;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(19, 19, 19, 0.3);
    box-shadow: inset 0 0 6px rgba(19, 19, 19, 0.3);
}