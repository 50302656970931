.app-header {
    grid-area: header;
    display: flex;
    align-items: center;

    .app-header-main-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100vw;
        height: 50px;
        font-size: rem(24px);
        z-index: 10;

        .guest-mode-container {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-inline-end: 20px;

            a {
                text-decoration: none;
                border: none;
                background-color: transparent;
                color: white;
                font-family: circular;
                line-height: 1.6;
            }

            &>*:last-child {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #F6F6F6;
                color: black;
                min-height: rem(80px);
                min-width: rem(150px);
                border-radius: 25px;
            }

            .vl {
                border-left: 1px solid white;
                height: 30px;
            }
        }

        .user-mode-container {
            gap: 15px;
            margin-inline-start: 5px;
            z-index: 20;
            cursor: pointer;

            .profile-details {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                padding: 2px 5px 2px 5px;
                background-color: #000;
                font-size: rem(13px);
                border-radius: 25px;
                margin-inline-end: 20px;
                color: #c9c9c9;
                width: rem(75px);
                height: rem(75px);
                z-index: 20;

                .profile-pic {
                    height: 80%;
                    width: 80%;
                    border-radius: 50%;
                }

                p {
                    display: none;
                }

                &:hover {
                    background-color: rgb(44, 44, 44);
                    transition: 0.3s;
                }
            }

            .app-header-profile-dropdown {
                position: absolute;
                top: 40px;
                right: 0;
                width: rem(250px);
                height: fit-content;
                gap: 12px;
                margin: 5px;
                background-color: #222222;
                border-radius: 15px;
                padding: 10px;
                margin-inline-end: 20px;
                z-index: 20;

                &>* {
                    color: #c9c9c9;
                    z-index: 20;
                    padding: 5px;
                }

                &>*:hover {
                    background-color: #757575;
                    cursor: pointer;
                }
            }
        }
    }

    @include for-tablet-layout {
        height: 65px;

        .app-header-main-container {
            justify-content: space-between;
            font-size: rem(14px);
            width: 100%;

            .guest-mode-container {
                // padding-inline-end: 35px;

                gap: 15px;

                &>*:last-child {
                    min-height: rem(47.5px);
                    min-width: rem(90px);
                }
            }

            .user-mode-container {
                .profile-details {
                    padding: 2px 10px 2px 5px;
                    width: max-content;
                    height: max-content;

                    p {
                        display: flex;
                        align-items: center;
                        margin: 0;
                    }

                    .profile-pic {
                        height: 35px;
                        width: 35px;
                    }

                }

                .app-header-profile-dropdown {
                    width: rem(160px);
                }
            }
        }
    }

    @include for-desktop-layout {
        .app-header-main-container {
            .guest-mode-container {
                font-size: rem(18px);
            }
        }

    }
}