.pagination-btn-container {
    margin-inline-start: rem(30px);
    width: rem(120px);
    gap: rem(5px);

    .pagination-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(40px);
        width: rem(40px);
        border-radius: 50%;
        background-color: #000;
        border: none;
        color: white;
        display: none;

        &>* {
            font-size: rem(30px);
        }

    }

    @include for-tablet-layout {
        &>*:nth-child(1) {
            display: unset;
        }
    }


    @include for-desktop-layout {
        &>*:nth-child(2) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}