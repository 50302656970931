    .login-signup-form {
        align-items: center;
        margin: auto;
        gap: rem(15px);

        & .login-signup-input {
            width: 100%;
            height: rem(50px);
            border: none;
            border-radius: 25px;
            padding-inline-start: rem(25px);
            font-size: rem(20px);

            &::placeholder {
                font-size: rem(20px);
            }
        }

        .error-msg {
            background-color: red;
            padding: 15px;
            border-radius: 25px;
        }

        .form-btn-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            margin-top: 75px;

            .login-signup-btn {
                width: rem(200px);
                height: rem(75px);
                color: white;
                background-color: $login-first-blue;
                font-size: rem(28px);
                border: none;
                border-radius: 45px;
            }

            a {
                font-size: rem(25px);
            }
        }
    }