.station-details {
    height: 100%;
    background: linear-gradient(transparent 0, $black 600px);

    .station-details-main-container {

        .station-search-container {
            .station-search-header {
                @include for-mobile-layout {
                    font-size: 17px;
                    margin-left: 20px;
                }

            }

            .search-form {
                position: unset;

                .search-bar-input {
                    margin: 15px 20px;
                    height: 40px;
                    width: 375px;
                    background-color: #ffffff1a;
                    border-radius: 4px;
                    color: #c0bfbf;
                    font-weight: 500;
                    font-size: rem(14px);
                    padding-left: 30px;

                    @include for-mobile-layout {
                        width: 90%;
                        text-align: center;
                    }
                }

                .search-btn {
                    top: 2px;
                    left: 52px;
                }

            }

            .station-details-search-list {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 rem(20px);
                max-width: $list-max-width;
            }
        }
    }

}