.clips-list {
    max-width: 1750px;
    margin-inline-start: rem(20px);

    .clip-list-header {
        display: grid;
        grid-template-columns: 3fr 20fr 5fr 5fr 7.5fr;
        align-items: center;
        padding: 4px;

        font-size: rem(20px);
        color: $grey-txt;

        .cl-play-btn {
            grid-column: 1/2;
            grid-column: 1/2;
            padding: rem(5px) rem(10px);
        }

        .artist {
            display: none;
        }

        .date-added {
            position: relative;
            width: max-content;
            display: none;
        }

        .title {
            grid-column: 2/3;
        }

        .duration {
            display: flex;
            justify-content: flex-start;
            padding-inline-start: 2.5px;

            grid-column: 4/5;

            svg {
                font-size: 3.5vw;
            }
        }
    }

    hr {
        margin-inline-start: rem(20px);
    }

    .clips-list-main-container {
        display: flex;
        flex-direction: column;
    }

    @include for-tablet-layout {
        .clip-list-header {
            font-size: rem(12px);

            .duration {
                svg {
                    font-size: rem(30px);
                }
            }
        }
    }

    @include for-desktop-layout {
        .clip-list-header {
            grid-template-columns: 1fr 20fr 5fr 5fr 5fr 5fr 5fr;
            align-items: center;

            .cl-play-btn {
                width: 30px;
            }

            .artist {
                grid-column: 3/4;
                display: block;
            }

            .date-added {
                grid-column: 4/5;
                display: block;
            }

            .duration {
                justify-content: flex-end;
                margin-inline-end: rem(10px);
                grid-column: 6;

                svg {
                    font-size: rem(25px);
                }
            }
        }
    }

    @include for-large-desktop-layout {
        .clip-list-header {
            font-size: rem(14px);
        }
    }
}